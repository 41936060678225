<template>
	<el-dialog :title="!dataForm.nodeId ? '新增节点' : '修改节点'" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="节点名称" prop="nodeName">
				<el-input class="selItemInput" v-model="dataForm.nodeName" placeholder="节点名称" style="width:300px"></el-input>
			</el-form-item>
			<el-form-item label="计划时间" prop="planTime">
				<el-date-picker class="selItemInput"
								value-format="yyyy-MM-dd"
								v-model="dataForm.planTime"
								type="date" style="width:300px">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="节点描述">
				<el-input class="selItemInput" type="textarea" placeholder="描述一下节点内容" rows="4" v-model="dataForm.remark"></el-input>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick @click="visible = false" class="primaryPlainBtn" type="primary" plain>取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "nodeList-add-or-update",
		data() {
			return {
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 5,
				// nodeinfoList : [],
				visible: false,
				inline: false,
				activeNames: ['1'],
				plannedTime: '',
				description: '',
				dataForm: {
					//编号(调用保存的方法)
					nodeId: '',
					//节点名称
					nodeName: '',
					//计划时间
					planTime: '',
					//备注
					remark: '',
				},
				dataRule: {
					remark: [
						{ required: true, message: "备注", trigger: "blur" }
					],
					nodeName: [
						{ required: true, message: "节点名称不能为空", trigger: "blur" }
					],
					planTime: [
						{ required: true, message: "计划时间不能为空", trigger: "blur" }
					]
				},
			}
		},
		methods: {
			init(id) {
				this.visible = true;
				this.dataForm = {
					       nodeId: '',
							//节点名称
							nodeName: '',
							//计划时间
							planTime: '',
							//备注
							remark: '',
				},
				this.$nextTick(() => {
					this.dataForm.nodeId = id ? id : 0;
					if (this.dataForm.nodeId) {
						this.$http({
							url: this.$store.state.httpUrl + `/business/projectnodeinfo/info/${this.dataForm.nodeId}`,
							method: 'get',
							params: this.$http.adornParams()
						}).then(({data}) => {
							if (data && data.resultCode === 200) {
								// this.dataForm.nodeId = data.body.nodeId
								this.dataForm.nodeName = data.body.nodeName
								this.dataForm.planTime = data.body.planTime
								this.dataForm.remark = data.body.remark
							}
						})
					}
				})
			},
			// 表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						this.$http({
							url:  this.$store.state.httpUrl +
									`/business/projectnodeinfo/${!this.dataForm.nodeId ?
											"save" : "update"}`,
							method: "post",
							data: this.$http.adornData({
								workNo: $common.getItem("workNo"),
								subjectNo:$common.getItem("subjectNo"),
								nodeId: this.dataForm.nodeId,
								nodeName:this.dataForm.nodeName,
								planTime:this.dataForm.planTime,
								remark:this.dataForm.remark
							})
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			},
			// //获取标的物
			// getNodeInfoList() {
			// 	// console.log("获取产品标的")
			// 	this.$http({
			// 		url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/"+$common.getItem("workNo"),
			// 		method: 'get'
			// 	}).then(({data}) => {
			// 		if (data && data.resultCode === 200) {
			// 			this.nodeinfoList = data.body;
			// 		} else {
			// 			this.nodeinfoList = []
			// 		}
			// 	})
			// },

		}
	}
</script>
<style scoped>

</style>
